<template>
  <v-app id="inspire">
    <v-container>
      <top-bar :navpos="items" />
      <v-main>
        <data-list />
      </v-main>
      <footer-bar/>
    </v-container>
  </v-app>
</template>

<script>
import TopBar from "../../components/TopBar.vue";
import DataList from "../../components/entity/ProjektList.vue";
import FooterBar from "../../components/Footer.vue";

export default {
  name: "Projekt",
  components: { TopBar, DataList, FooterBar },
  props: {},
  data() {
    return {
      items: [
        {
          text: this.$t("nav.main"),
          disabled: false,
          to: { path: "/main" }
        },
        {
          text: this.$t("nav.entity"),
          disabled: false,
          href: "main"
        },
        {
            text: this.$t("entity.list.projekt"),
            disabled: true,
            hfref : "projekt"
        }
      ],
    };
  },
  computed: {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.center {
  margin: auto;
  width: 70%;
  padding: 10px;
  text-align: center;
}
</style>
